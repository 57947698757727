<template>
  <div class="content">
    <PageHeader :title="$t('acc-settings.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col">
          <div class="card">
            <form @submit.prevent="submitData" class="form-horizontal">
              <div class="card-body">
                <div class="form-body">
                  <div class="form-group row">
                    <label class="control-label text-right col-md-3 text-left-m">{{$tc('generic-str.name', 1)}}</label>
                    <div class="col-md-5">
                      <input
                        v-model="form.name"
                        type="text"
                        :placeholder="$tc('generic-str.name', 1)"
                        class="form-control"
                        autocomplete="given-name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-3 text-left-m">Email</label>
                    <div class="col-md-5">
                      <input
                        v-model="form.email"
                        type="email"
                        :placeholder="$t('login-invite.email-address')"
                        class="form-control"
                        autocomplete="email"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-3 text-left-m">{{$t('generic-str.lbl-cellphone')}}</label>
                    <div class="col-md-5">
                      <vue-tel-input
                        v-model="mobile_number"
                        v-bind="bindProps" @validate="numberChanges"></vue-tel-input>
                      <div class="invalid-feedback" :class="{'show':invalidPhone}">{{$t('login-invite.invalid-phone')}}</div>
                    </div>
                  </div>
                  <div class="form-group row" v-if="!changePass">
                    <label class="control-label text-right col-md-3 text-left-m"></label>
                    <div class="col-md-5">
                      <button
                          class="btn btn-danger btn-rounded"
                          @click="changePass = true"
                          >{{$t('acc-settings.chg-pass')}}</button>
                    </div>
                  </div>
                  <div class="form-group row" v-if="changePass">
                    <label class="control-label text-right col-md-3 text-left-m">{{$t('acc-settings.new-pass')}}</label>
                    <div class="col-md-5">
                      <div class="input-group mb-3">
                        <input v-model="newPass" :type="type" class="form-control" />
                        <div class="input-group-prepend">
                          <button type="button" class="btn btn-primary btn-icon height-35" @click="toggleVisibility">
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-right col-md-3 text-left-m">{{$t('general-settings.title')}} <span class="badge badge-warning">Beta</span></label>
                    <div class="col-md-5">
                      <OptLang />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light">
                <div class="form-actions">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="offset-sm-3 col-md-5">
                          <button
                            :disabled="isSending"
                            class="btn btn-primary btn-rounded" :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
                          >{{$t('acc-settings.update-infos')}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UserService from '@/services/user.service';
import PageHeader from '@/components/PageHeader.vue';
import { VueTelInput } from 'vue-tel-input';
import OptLang from '@/components/OptLang.vue';

export default {
  name: 'Home',
  components: {
    PageHeader,
    VueTelInput,
    OptLang,
  },
  data() {
    return {
      type: 'password',
      invalidPhone: false,
      mobile_number: null,
      changePass: false,
      isSending: false,
      newPass: null,
      infoUser: {},
      form: {
        name: '',
        email: '',
        mobile_number: '',
      },
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  created() {
    this.fetchInfo();
  },
  methods: {
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.form.mobile_number = rest.number.input;
        }
      }
    },
    toggleVisibility() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    fetchInfo() {
      UserService.getInfo().then(
        (response) => {
          this.form = response;
          this.mobile_number = this.form.mobile_number;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    submitData() {
      if (!this.invalidPhone) {
        this.isSending = true;
        if (this.changePass && this.newPass != null) {
          this.form.password = this.newPass;
        }
        UserService.updateUserInfo(this.form).then(
          (response) => {
            this.isSending = false;
            this.form = response;
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('acc-settings.toast.updated'),
              type: 'success',
            });
          },
          (error) => {
            this.isSending = false;
            this.content = error;
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        );
      }
    },
  },
};
</script>

<style lang="scss">
.vti__dropdown-list{
  z-index: 1000 !important;
}
.vue-tel-input{
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120,141,180,.3) !important;
}
</style>
