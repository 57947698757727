import axios from '@/plugins/axios';

class UserService {
  getInfo() {
    this.a = 'a';
    return axios().get('users/info')
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getUser(id) {
    this.a = 'a';
    return axios().get(`users/${id}`).then((response) => response.data);
  }

  getUsers(data) {
    this.data = data;
    return axios().get('users', { params: this.data }).then((response) => response.data);
  }

  countUsers() {
    this.a = 'a';
    return axios().get('users/count')
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  updateUser(id, data) {
    this.id = id;
    return axios().put(`users/${id}`, data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  updateUserInfo(data) {
    this.data = data;
    return axios().put('users/info', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  removeUser(id) {
    this.id = id;
    return axios().delete(`users/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  invite(data) {
    this.data = data;
    return axios().post('users/invite', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getInvitation(token) {
    this.token = token;
    return axios().get(`invitation/${token}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new UserService();
